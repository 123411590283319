import { createSlice } from "@reduxjs/toolkit";
import Moment from "moment";
const initialState = {
  filtros: {
    tags: [],
    colunas: {},
    b2c: true,
    b2b: true,
    dataInicial: Moment().subtract(1, "month").startOf("month").toDate(),
    dataFinal: new Date(),
    idCompany: "",
    company: null,
    pesquisa: "",
    idRequestView: "",
    horaInicial: Moment()
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    horaFinal: Moment()
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .toDate(),
  },
};
const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFiltros(state, action) {
      state.filtros = action.payload;
    },

    resetDashboard(state) {
      state.filtros = initialState.filtros;
    },
  },
});

export default dashboard.reducer;

export const { setFiltros, resetDashboard } = dashboard.actions;
